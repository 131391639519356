import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const ConsulTrust = () => {
  const data = useStaticQuery(graphql`
    query {
      family: file(relativePath: { eq: "family.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `信託とは、「自分の財産を、信頼できる人に託し、その財産を決められた目的に従って、自分または自分の大切な人のためにその財産を管理・運用・処分してもらう」仕組みです。
そして、その登場人物が家族内の人だけでなされているものを特に「家族信託」と呼んでいます。
少し難しく分かりにくい仕組みですが、認知症対策として、生前に財産の管理処分権限を次の世代に渡したり、親亡き後の支援が必要な子の保護をしたりすることができます。
信託契約に定めることにより、様々な設計が考えられますので、ご興味がある方はお気軽にご相談ください。
`

  return (
    <ServiceContent
      name={"consul__family"}
      title={"家族信託"}
      content={content}
      fluid={data.family.childImageSharp.fluid}
      link={"/familytrust"}
    />
  )
}

export default ConsulTrust
