import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const ConsulSouzoku = () => {
  const data = useStaticQuery(graphql`
    query {
      souzoku: file(relativePath: { eq: "many-papers.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `身近な方がお亡くなりになると、不動産等、財産の名義を相変更する必要があります。
遺言の有無、相続人の確定、財産調査など、何から手をつければ良いのか分からない。
そういった、お悩みをお持ちになられたら、是非、一度ご相談ください。何もせずに放っておくと、相続手続が難しくなり、多くの時間とコストを費やすことになる場合があります。`

  return (
    <ServiceContent
      name={"consul__souzoku"}
      title={"相続"}
      content={content}
      fluid={data.souzoku.childImageSharp.fluid}
      link={"/souzoku/"}
    />
  )
}

export default ConsulSouzoku
