import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"

import ConsulSouzoku from "../components/service/consulSouzoku"
import ConsulIgon from "../components/service/consulIgon"
import ConsulTrust from "../components/service/consulTrust"
import BackContact from "../components/backContact"

const Consul = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      trust: file(relativePath: { eq: "trust.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "皆様の身近な問題である相続や、相続対策としての遺言、家族信託について、大阪の司法書士事務所、司法書士法人みつわ合同事務所がご紹介します。"
  return (
    <Layout>
      <SEO
        description={description}
        title="相続　遺言　家族信託 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.trust.childImageSharp.original.src}
        pageimgw={data.trust.childImageSharp.original.width}
        pageimgh={data.trust.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"consul"}
          fluid={data.trust.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"相続・遺言・家族信託概要"}
        />
        <section className="consul">
          <div className="consul__container">
            <h1 className="consul__title">相続・遺言・家族信託概要</h1>
            <div className="consul__service__list">
              <ConsulSouzoku />
              <ConsulIgon />
              <ConsulTrust />
            </div>
          </div>
        </section>
        <BackContact link={"/"} linkName={"Topページ"} />
      </div>
    </Layout>
  )
}

export default Consul
