import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const ConsulIgon = () => {
  const data = useStaticQuery(graphql`
    query {
      igon: file(relativePath: { eq: "igon.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `遺言書を作成することによって、相続トラブル防止に役立つだけでなく、相続手続をスムーズに進めることもできます。
    ただし、正しい方法、内容で作成しなければ、遺言が無効になってしまったり、相続トラブルをより大きなものにしてしまったりと、思ってもみなかったことになってしまうこともあります。
    そこで、遺言書作成をご検討されている方は、是非、ご相談ください。
    `

  return (
    <ServiceContent
      name={"consul__igon"}
      title={"遺言"}
      content={content}
      fluid={data.igon.childImageSharp.fluid}
      link={"/igon/"}
    />
  )
}

export default ConsulIgon
